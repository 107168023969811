/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';
import { Win } from 'src/app/model/win.model';

@Injectable({
  providedIn: 'root',
})
class WinDataService extends __BaseService {
  static readonly getWelldataPath = '/WinData';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }
  getWelldataResponse(): __Observable<__StrictHttpResponse<Win[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/WinData`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Win[]>;
      })
    );
  }  getWelldata(): __Observable<Win[]> {
    return this.getWelldataResponse().pipe(
      __map(_r => _r.body as Win[])
    );
  }


  getWelldataResponseByApi(api:string): __Observable<__StrictHttpResponse<Win[]>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/WinData/`+ api,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Win[]>;
      })
    );
  }  getWelldataByApi(api:string): __Observable<Win[]> {
    return this.getWelldataResponseByApi(api).pipe(
      __map(_r => _r.body as Win[])
    );
  }


  GetRowCountResponse(): __Observable<__StrictHttpResponse<number>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/WinData/count`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<number>;
      })
    );
  }  GetRowCount(): __Observable<number> {
    return this.GetRowCountResponse().pipe(
      __map(_r => _r.body as number)
    );
  }

}

module WinDataService {
}

export { WinDataService }
