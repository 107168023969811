import { Component, OnInit, ViewChild } from '@angular/core';
import { Validators, FormBuilder, FormArray } from '@angular/forms';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { Win } from '../model/win.model';
import { WinService } from '../service/Win.service';
import { ToastrService } from 'ngx-toastr';
import { WinDataService } from '../api/services';
import { debug } from 'util';
import { forkJoin } from 'rxjs';


@Component({
  selector: 'app-win-search',
  templateUrl: './win-search.component.html',
  styleUrls: ['./win-search.component.css']
})

export class WinSearchFormComponent implements OnInit {
  maxRowCount: number=0;
  displayedColumns: string[] 
  ELEMENT_DATA:  Win[] 
  dataSource = new MatTableDataSource<Win>(this.ELEMENT_DATA);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  constructor(private wellService: WinService,
    private welldataservice: WinDataService,
    private toastr: ToastrService, 
    private fb: FormBuilder) { 

     
    }
    
  ngOnInit() {
    this.displayedColumns = [ 'operationsArea','costCenter', 'osArea','welWellName', 'api12', 'ctbName', 'padName',
    'popdate', 'completionDate', 'spudDate','wellType'];

    this.getWellList();
  }

  form = this.fb.group({
    api12: ['', Validators.required]
  });

  formValidationMessages = {
    api12: [
      { type: 'required', message: 'Username is required' }
    ]
  };

  valuechange() {
    this.ELEMENT_DATA=[]
    this.dataSource = new MatTableDataSource<Win>(this.ELEMENT_DATA);
  let val = this.form.get('api12').value
  if(val || val.length>=1) {

    this.onSubmit()
  }
  }

  getWellList(): void {
    
    this.welldataservice.getWelldata().subscribe(data => {
      this.ELEMENT_DATA = data

      this.dataSource = new MatTableDataSource<Win>(this.ELEMENT_DATA);
      this.dataSource.paginator = this.paginator;

    });

 }


 onSubmit() : void {
  this.ELEMENT_DATA=[]
  let val = this.form.get('api12').value
   this.welldataservice.getWelldataByApi(val).subscribe(data => {this.ELEMENT_DATA = data;
  this.dataSource = new MatTableDataSource<Win>(this.ELEMENT_DATA);
  this.dataSource.paginator = this.paginator;
  if(this.ELEMENT_DATA.length <1){
    this.toastr.info('No maching Data found')
  
      }
  });
 
 }
}
