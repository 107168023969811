import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import{  WinSearchFormComponent } from './win-search/win-search.component'; 
const routes: Routes = [
  { path: '', redirectTo: '/win-search', pathMatch: 'full' },
  { path: 'win-search', component: WinSearchFormComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
