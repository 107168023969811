import { Injectable } from '@angular/core';
import { Win } from '../model/win.model';
import { Observable} from 'rxjs';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WinService {
  wellList :  Win[];
    constructor() { 
      this.wellList = [
        // {
        //   "WellBoreNumber": 421212,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "50670673500",
        //   "LocalWellNumber": "14 MV",
        //   "CHEVNO": "FL2995",
        //   "OriginalOperatorName": "GETTY OIL CO",
        //   "LeaseName": "BURCH SAM",
        //   "LeaseNumber": "",
        //   "WellName": "BURCH 14 FLC",
        //   "WellNumber": "14 FLC"
        // },
        // {
        //   "WellBoreNumber": 421260,
        //   "WHMCOMPNUMBER": 2,
        //   "API12": "50810571000",
        //   "LocalWellNumber": "",
        //   "CHEVNO": "DE4484",
        //   "OriginalOperatorName": "CHEVRON U S A INC",
        //   "LeaseName": "VAN SCHAICK",
        //   "LeaseNumber": "3828",
        //   "WellName": "V-SCHK A 3",
        //   "WellNumber": "3"
        // },
        // {
        //   "WellBoreNumber": 421336,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "51030912700",
        //   "LocalWellNumber": "66",
        //   "CHEVNO": "FT1235",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "WILSON CREEK",
        //   "LeaseNumber": "",
        //   "WellName": "WILSON CREEK UNIT 66",
        //   "WellNumber": "66"
        // },
        // {
        //   "WellBoreNumber": 372463,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300150564900",
        //   "LocalWellNumber": "29",
        //   "CHEVNO": "FC6281",
        //   "OriginalOperatorName": "SILVERTHRONE R Q",
        //   "LeaseName": "KENWOOD-FEDERAL",
        //   "LeaseNumber": "",
        //   "WellName": "BENSON SHUGART WFU 29",
        //   "WellNumber": "29"
        // },
        // {
        //   "WellBoreNumber": 55973,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300151076900",
        //   "LocalWellNumber": "1",
        //   "CHEVNO": "FF4401",
        //   "OriginalOperatorName": "GULF OIL CORP",
        //   "LeaseName": "HELBING-FEDERAS COM",
        //   "LeaseNumber": "",
        //   "WellName": "HELBING FEDERAL GAS COM 1",
        //   "WellNumber": "1"
        // },
        // {
        //   "WellBoreNumber": 372517,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300152009200",
        //   "LocalWellNumber": "11",
        //   "CHEVNO": "FG2584",
        //   "OriginalOperatorName": "PAN AMERICAN",
        //   "LeaseName": "BIG EDDY UNIT",
        //   "LeaseNumber": "",
        //   "WellName": "BIG EDDY UNIT 11",
        //   "WellNumber": "11"
        // },
        // {
        //   "WellBoreNumber": 372460,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300152021300",
        //   "LocalWellNumber": "21",
        //   "CHEVNO": "FG4650",
        //   "OriginalOperatorName": "HANSON OIL CORP",
        //   "LeaseName": "GINSBERG-FEDERAL",
        //   "LeaseNumber": "",
        //   "WellName": "BENSON SHUGART WFU 21",
        //   "WellNumber": "21"
        // },
        // {
        //   "WellBoreNumber": 372497,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300152023300",
        //   "LocalWellNumber": "22",
        //   "CHEVNO": "FG5012",
        //   "OriginalOperatorName": "HANSON OIL CORP",
        //   "LeaseName": "GINSBERG-FEDERAL",
        //   "LeaseNumber": "",
        //   "WellName": "BENSON SHUGART WFU 22",
        //   "WellNumber": "22"
        // },
        // {
        //   "WellBoreNumber": 372562,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300250096200",
        //   "LocalWellNumber": "2",
        //   "CHEVNO": "FA2126",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "LITTL EDDY UNIT ST",
        //   "LeaseNumber": "",
        //   "WellName": "LITTLE EDDY UNIT 2",
        //   "WellNumber": "2"
        // },
        // {
        //   "WellBoreNumber": 100790,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300253382000",
        //   "LocalWellNumber": "2",
        //   "CHEVNO": "BL1265",
        //   "OriginalOperatorName": "CHEVRON PRODUCTION COMPANY",
        //   "LeaseName": "WEIR B 2",
        //   "LeaseNumber": "",
        //   "WellName": "WEIR \"B\" 2",
        //   "WellNumber": "2"
        // },
        // {
        //   "WellBoreNumber": 441066,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300392343400",
        //   "LocalWellNumber": "28E DK",
        //   "CHEVNO": "DY4002",
        //   "OriginalOperatorName": "GETTY OIL CO",
        //   "LeaseName": "JICARILLA-C",
        //   "LeaseNumber": "",
        //   "WellName": "JICARILLA C 28E DK",
        //   "WellNumber": "28E DK"
        // },
        // {
        //   "WellBoreNumber": 429326,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "300452496900",
        //   "LocalWellNumber": "S 1 CHA",
        //   "CHEVNO": "AI1695",
        //   "OriginalOperatorName": "GETTY OIL CO",
        //   "LeaseName": "MEXICO FEDERAL-S",
        //   "LeaseNumber": "",
        //   "WellName": "MEXICO FED S 1 CHA",
        //   "WellNumber": "S 1 CHA"
        // },
        // {
        //   "WellBoreNumber": 429350,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "350112047700",
        //   "LocalWellNumber": "",
        //   "CHEVNO": "GA5996",
        //   "OriginalOperatorName": "FERGUSON OIL",
        //   "LeaseName": "COFFEY",
        //   "LeaseNumber": "",
        //   "WellName": "COFFEY 1",
        //   "WellNumber": "1"
        // },
        // {
        //   "WellBoreNumber": 429358,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "350113001200",
        //   "LocalWellNumber": "",
        //   "CHEVNO": "GA1352",
        //   "OriginalOperatorName": "SKELLY OIL CO",
        //   "LeaseName": "LOTE FOSTER",
        //   "LeaseNumber": "11149",
        //   "WellName": "LOTE FOSTER 1",
        //   "WellNumber": "1"
        // },
        // {
        //   "WellBoreNumber": 429364,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "350172088900",
        //   "LocalWellNumber": "",
        //   "CHEVNO": "GY8176",
        //   "OriginalOperatorName": "GETTY OIL CO",
        //   "LeaseName": "M L HARRINGTON",
        //   "LeaseNumber": "",
        //   "WellName": "HARRINGTON 2",
        //   "WellNumber": "2"
        // },
        // {
        //   "WellBoreNumber": 441108,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "351373009800",
        //   "LocalWellNumber": "9 TU",
        //   "CHEVNO": "GR0711",
        //   "OriginalOperatorName": "SKELLY OIL CO",
        //   "LeaseName": "FRENSLEY J",
        //   "LeaseNumber": "11646",
        //   "WellName": "VTU 09",
        //   "WellNumber": "9"
        // },
        // {
        //   "WellBoreNumber": 430179,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "351373075100",
        //   "LocalWellNumber": "5 DHAHU",
        //   "CHEVNO": "GR0943",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "A N HARLEY",
        //   "LeaseNumber": "",
        //   "WellName": "HARLEY 05 DHAHU",
        //   "WellNumber": "5 DHAHU"
        // },
        // {
        //   "WellBoreNumber": 430180,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "351373077500",
        //   "LocalWellNumber": "11 FURV",
        //   "CHEVNO": "GR0967",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "HARLEY A N",
        //   "LeaseNumber": "",
        //   "WellName": "HARLEY 11 FURV",
        //   "WellNumber": "11 FURV"
        // },
        // {
        //   "WellBoreNumber": 430181,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "351373078100",
        //   "LocalWellNumber": "1 CLBHX",
        //   "CHEVNO": "GR0973",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "HARRELL H `B`",
        //   "LeaseNumber": "",
        //   "WellName": "HARRELL C 2 HU",
        //   "WellNumber": "2 HU"
        // },
        // {
        //   "WellBoreNumber": 430738,
        //   "WHMCOMPNUMBER": 2,
        //   "API12": "351393611700",
        //   "LocalWellNumber": "",
        //   "CHEVNO": "GH9205",
        //   "OriginalOperatorName": "SKELLY OIL CO",
        //   "LeaseName": "L M HITCH LEASE",
        //   "LeaseNumber": "",
        //   "WellName": "HITCHLM 1",
        //   "WellNumber": "1"
        // },
        // {
        //   "WellBoreNumber": 431322,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "420033160200",
        //   "LocalWellNumber": "482W",
        //   "CHEVNO": "EP9050",
        //   "OriginalOperatorName": "TEXACO INC",
        //   "LeaseName": "MABEE J E /A/NCT-1",
        //   "LeaseNumber": "",
        //   "WellName": "MABEE UNIT 482W",
        //   "WellNumber": "482W"
        // },
        // {
        //   "WellBoreNumber": 100783,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "421033352101",
        //   "LocalWellNumber": "1044S",
        //   "CHEVNO": "DY0387",
        //   "OriginalOperatorName": "CHEVRON PRODUCTION COMPANY",
        //   "LeaseName": "MCELROY, J. T., CONS.",
        //   "LeaseNumber": "",
        //   "WellName": "MCELROY, J. T., CONS. 1044S",
        //   "WellNumber": "1044S"
        // },
        // {
        //   "WellBoreNumber": 60765,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "421350118400",
        //   "LocalWellNumber": "811",
        //   "CHEVNO": "EA7074",
        //   "OriginalOperatorName": "CHEVRON PRODUCTION COMPANY",
        //   "LeaseName": "GOLDSMITH C A ETAL",
        //   "LeaseNumber": "19915",
        //   "WellName": "GOLDSMITH C A ETAL 811",
        //   "WellNumber": "811"
        // },
        // {
        //   "WellBoreNumber": 433532,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "421650013400",
        //   "LocalWellNumber": "92W",
        //   "CHEVNO": "FB7140",
        //   "OriginalOperatorName": "UNION TEXAS PET CORP",
        //   "LeaseName": "MORTON",
        //   "LeaseNumber": "5107",
        //   "WellName": "WHARTON UNIT 92W",
        //   "WellNumber": "92W"
        // },
        // {
        //   "WellBoreNumber": 433533,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "421650047000",
        //   "LocalWellNumber": "301",
        //   "CHEVNO": "FB7462",
        //   "OriginalOperatorName": "BLACK W H DRILLING",
        //   "LeaseName": "NOBLE FOUNDATION IN",
        //   "LeaseNumber": "18728",
        //   "WellName": "SOUTH HARRIS UNIT 301",
        //   "WellNumber": "301"
        // },
        // {
        //   "WellBoreNumber": 28343,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "423710444500",
        //   "LocalWellNumber": "5D",
        //   "CHEVNO": "ED9558",
        //   "OriginalOperatorName": "CHEVRON PRODUCTION COMPANY",
        //   "LeaseName": "ROBBINS, J W A1",
        //   "LeaseNumber": "20621",
        //   "WellName": "ROBBINS, J W 1A 5",
        //   "WellNumber": "5"
        // },
        // {
        //   "WellBoreNumber": 28345,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "423710574400",
        //   "LocalWellNumber": "2D",
        //   "CHEVNO": "EE0821",
        //   "OriginalOperatorName": "STANDARD OIL CO TX",
        //   "LeaseName": "MITCHELL-THOMUNIT 1",
        //   "LeaseNumber": "20657",
        //   "WellName": "MITCHELL-THOMAS UNIT 1 2D",
        //   "WellNumber": "2D"
        // },
        // {
        //   "WellBoreNumber": 28387,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "423713029600",
        //   "LocalWellNumber": "1",
        //   "CHEVNO": "EM6855",
        //   "OriginalOperatorName": "CHEVRON U S A INC",
        //   "LeaseName": "BLACKSTONE-SLAUGHTE",
        //   "LeaseNumber": "",
        //   "WellName": "BLACKSTONE-SLAUGHTER 1",
        //   "WellNumber": "1"
        // },
        // {
        //   "WellBoreNumber": 58309,
        //   "WHMCOMPNUMBER": 1,
        //   "API12": "423713391000",
        //   "LocalWellNumber": "1",
        //   "CHEVNO": "DM2394",
        //   "OriginalOperatorName": "CHEVRON PRODUCTION COMPANY",
        //   "LeaseName": "LITTLE MEXICO GAS",
        //   "LeaseNumber": "",
        //   "WellName": "LITTLE MEXICO GAS 1",
        //   "WellNumber": "1"
        // }
      ]
    }

  getWellList( val: string): Observable<Win[]>  {

    let res  = this.wellList.filter(obj=> obj.api12.search(val) == 0);
    return of (res)
  }
}
